Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("product-config-step-5")) {
        Global.components.push(
            new Vue({
                el: document.getElementById("product-config-step-5"),
                store,
                data: function () {
                    return {
                        submitUrl: window.location.origin + '/dashboard/configurator',
                        loadingResults: false,
                        showFilters: true,
                        searchQuery: '',
                        filters: [],
                        filterValues: [],
                        products: [],
                        rawResponse: null,
                        isAccountManager: document.getElementById("product-config-step-5").dataset.isAccountManager,
                        perPage: 20,
                        perPageOptions: [20, 50, 100, 200],
                        showLocation: true,
                        submitFormStepAllowed: false,
                        summaryUploadTitle: '',
                        containers: 0,
                        maintenanceUnits: 0
                    }
                },
                computed: {
                    configurator: {
                        get() {
                            return this.$store.state.configurator;
                        },
                        set: function (value) {
                            this.$store.dispatch('setConfigurator', value)
                        }
                    },
                    configuratorProductRemoved: {
                        get() {
                            return this.$store.state.configuratorProductRemoved;
                        },
                        set: function (value) {
                            this.$store.dispatch('setConfiguratorProductRemoved', value)
                        }
                    },
                    nextStepAllowed() {
                        return this.configurator.step === 5 &&
                            (
                                this.isAccountManager && this.configurator.places !== undefined && this.configurator.places.available < this.configurator.places.total
                                ||
                                this.configurator.places !== undefined && this.configurator.places.available === 0
                            );
                    },
                    messageText: {
                        get: function () {
                            return this.$store.getters.messageText
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMessageText', newValue)
                        }
                    }
                },
                watch: {
                    configurator: {
                        handler(newValue, oldValue) {
                            this.checkIfSubmitFormStepAllowed()
                            if (newValue.step === 5) {
                                this.containers = this.configurator.containers || 0;
                                this.maintenanceUnits = this.configurator.maintenanceUnits || 0;
                            }
                        },
                        deep: true
                    },
                    containers() {
                        this.configurator.containers = parseFloat(this.containers);
                        this.checkIfSubmitFormStepAllowed();
                    },
                    maintenanceUnits() {
                        this.configurator.maintenanceUnits = parseFloat(this.maintenanceUnits);
                        this.checkIfSubmitFormStepAllowed();
                    }
                },
                mounted: function () {
                    if (this.nextStepAllowed) {
                        this.showFilters = false;
                    }
                },
                methods: {
                    uploadSummaryImage(e) {
                        const that = this;
                        this.uploadingInProgress = true;

                        let formData = new FormData();
                        formData.append('file', e.target.files.item(0));

                        API.POST('/dashboard/upload/temporary', formData).then(response => {
                            that.uploadingInProgress = false;
                            that.configurator.summaryImageTitle = response.data.title;
                            that.configurator.summaryUploadTitle = response.data.title;
                            that.configurator.summaryUpload = response.data.id;
                            that.summaryUploadTitle = response.data.title;
                        });
                    },
                    checkIfSubmitFormStepAllowed() {
                        let isAllowed = true;

                        this.submitFormStepAllowed = isAllowed;

                        return isAllowed;
                    },
                    submitStep() {
                        const that = this;

                        let data = JSON.parse(JSON.stringify(this.configurator));

                        API.PUT(this.submitUrl + '/' + this.configurator.id, data).then(response => {
                            that.configurator = response.data;

                            let formData = new FormData();
                            formData.append('configuration', this.configurator.id);

                            API.POST(window.location.origin + '/dashboard/creations', formData).then(response => {
                                Barba.Pjax.goTo(response.target);
                            });
                        });
                    },
                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});
