Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("copy-board-button")) {
        Global.components.push(
            new Vue({
                el: '#copy-board-button',
                store,
                data: function () {
                    return {
                        loading: false,
                        complete: false,
                        copied: false,
                        copyUrl: '#'
                    }
                },
                computed: {
                    errorObject: {
                        get: function () {
                            return this.$store.getters.errorObject
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setErrorObject', newValue)
                        }
                    },
                    messageText: {
                        get: function () {
                            return this.$store.getters.messageText
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMessageText', newValue)
                        }
                    },

                },
                methods: {
                    copyMoodboard: function (url, moodboardID) {
                        if(!this.copied && !this.loading){
                            this.loading = true;
                            const that = this;
                            let formData = new FormData();
                            formData.append('moodboard', moodboardID);

                            API.POST(url, formData).then(response => {
                                if(response.status === 'success'){
                                    that.loading = false;
                                    that.copied = true;
                                    that.copyUrl = response.url
                                    that.messageText = response.message;
                                    window.open(response.url, '_blank').focus();
                                }else if(response.status === 'error'){
                                    that.errorObject = response.data;
                                    this.loading = false;
                                    this.copied = false;
                                }
                            });
                        }
                    }
                },
            })
        )
    }
});
