Vue.component('signature', {
    props : ['value'],
    data() {
        return {
            content: this.value,
            ctx :  null,
            canvas: null,
            sign :  false,
            prevX :  0,
            prevY :  0,
        }
    },
    methods : {
        mousedown($event) {
            this.sign  =  true
            this.prevX  = $event.offsetX
            this.prevY  = $event.offsetY
        },
        mousemove($event) {
            if(this.sign) {
                const  currX  = $event.offsetX
                const  currY  = $event.offsetY
                this.draw(this.prevX, this.prevY, currX, currY)
                this.prevX  =  currX
                this.prevY  =  currY
            }
        },
        draw(depX, depY, destX, destY) {
            this.ctx.beginPath()
            this.ctx.moveTo(depX, depY)
            this.ctx.lineTo(destX, destY)
            this.ctx.closePath()
            this.ctx.stroke()

            this.content = this.canvas.toDataURL('image/png');//.replace('image/png', 'image/octet-stream')
            this.$emit('input', this.content)
        },
        clear() {
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
            this.content = null;
            this.$emit('input', this.content);
        },
        resizeCanvas() {
            const ratio = Math.max(window.devicePixelRatio || 1, 1);
            this.canvas.width = this.canvas.offsetWidth * ratio;
            this.canvas.height = this.canvas.offsetHeight * ratio;
            this.ctx.scale(ratio, ratio);
        }
    },
    watch : {
        content(model) {
            if(!model) {
                this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
            }
        }
    },
    mounted() {
        this.canvas = this.$refs.canvas;
        this.ctx = this.canvas.getContext('2d')
        this.ctx.strokeStyle = 'black'
        this.ctx.lineWidth = 50
        this.resizeCanvas();
    },
    template:
    '<div>\n' +
    '    <canvas class="signature__canvas" ref="canvas" @mousedown="mousedown" @mousemove="mousemove" @mouseup="sign = false" @mouseout="sign = false" />\n' +
    '    <button type="button" @click.prevent="clear">Opnieuw</button>\n' +
    '</div>'
});
